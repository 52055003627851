import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader, Polygon, InfoWindow } from '@react-google-maps/api';
import AreaService from '../../services/AreaService';
import { Autocomplete, Box, FormControl, TextField } from '@mui/material';
import CityService from '../../services/CityService';
import {useNavigate} from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const options1 = {
  fillColor: 'red',
  fillOpacity: 0.5,
  strokeColor: 'black',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

function MgAllocation() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  const [, setMap] = useState(null);
  const [allBoundarys, setAllBoundarys] = useState([]);
  const [cities, setCities] = useState<any>([]);
  const [city, setCity] = useState<any>([{ title: 'Gurgaon', id: 1 }]); // Default city as 'Gurgaon'
  const [hoveredBoundary, setHoveredBoundary] = useState<any>(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState<any>(null);
  const [center, setCenter] = useState<any>({ lat: 28.449180, lng: 77.030228 });

  const navigate = useNavigate();

  const onPolygonMouseOver = (boundary: any) => {
    setHoveredBoundary(boundary);
    setInfoWindowPosition(boundary.position);
  };

  const onPolygonMouseOut = () => {
    setHoveredBoundary(null);
    setInfoWindowPosition(null);
  };

  const areaService = useMemo(() => new AreaService(), []);
  const cityService = useMemo(() => new CityService(), []);

  useEffect(() => {
    cityService.fetchAllCities(1000, 0, { isCurrentlyServing: 'YES' }, {}).then((res) => {
      setCities(res?.data);
    });
  }, [cityService]);

  useEffect(() => {
    if (city.length > 0) {
      areaService
        .fetchAllAreas(1000, 0, { cityId: city.map((c: any) => c.id+"") }, {})
        .then(function (response) {
          let areas: any = response?.data.filter(
            (area: any) => area?.enclosingPolygon && area?.enclosingPolygon?.coordinates.length > 0
          );
          let allAreaCoords: any = [];
          setCenter({
            lat: areas[0]?.centroid?.coordinates[1],
            lng: areas[0]?.centroid?.coordinates[0],
          });
          for (let area of areas) {
            let coordinates = area?.enclosingPolygon?.coordinates[0]; // The array of polygon points
            let polygonPoints = [];
            let center = {
              lat: area?.centroid?.coordinates[1],
              lng: area?.centroid?.coordinates[0],
            }; // Extracting the centroid

            for (let point of coordinates) {
              polygonPoints.push({
                lat: point[1],
                lng: point[0],
              });
            }

            allAreaCoords.push({
              id: area.id,
              name: area.name,
              points: polygonPoints,
              cityId: area.cityId,
              position: center,
              isChefitServicable: area.isChefitServicable,
            });
          }

          const seen = new Set();
          allAreaCoords = allAreaCoords.filter((area: any) => area.points.length !== 0);
          const filteredArr = allAreaCoords.filter((area: any) => {
            const duplicate = seen.has(area?.name + area?.cityId);
            seen.add(area?.name + area?.cityId);
            return !duplicate;
          });

          setAllBoundarys(filteredArr);
          
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [city, areaService]);

  const onClickHander = (e: any) => {
    navigate(`/Areas/${e?.id}/show/1`)
  };

  const onLoad = useCallback(function callback(e: any) {
    setMap(e);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <Box sx={{ width: 200 }}>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            id="tags-standard"
            value={city}
            options={cities?.map((city: any) => {
              return { title: city?.name, id: city?.id };
            })}
            onChange={(e, v: any) =>setCity(v)}
            getOptionLabel={(option: any) => option.title}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Cities" placeholder="Enter your cities" />
            )}
          />
        </FormControl>
      </Box>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {allBoundarys.map((e: any) => (
          <div key={`${ e.name } cityId-${ e.cityId }`}>
            <Polygon
              options={ !e.isChefitServicable ? options1 : {...options1 , fillColor : 'lightgreen' }}
              paths={ e.points }
              onClick={() => onClickHander(e)}
              onMouseOver={() => onPolygonMouseOver(e)}
              onMouseOut={onPolygonMouseOut}
            />
          </div>
        ))}
        {hoveredBoundary && (
          <InfoWindow position={infoWindowPosition} options={{ disableAutoPan: true }}>
            <div style={{ fontWeight: '600' }}>{hoveredBoundary.name}</div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(MgAllocation);

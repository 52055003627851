import "kalend/dist/styles/index.css"; // import styles
import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Link,
  TextField,
  Box, Select,
  FormControl,
  InputLabel,
  MenuItem, Typography,
  Autocomplete
} from "@mui/material";
import { Authenticated } from "ra-core";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { CityEnum, CityIdMapping } from "../../enums/CityEnum";
import { CookService } from "../../services/CookServices";
import '../OmsTimeTable/index.css';
import { OneMealService } from "../../services/OneMealService";
import { TrialsService } from "../../services/TrialServices";
import UserBundleService from "../../services/BundleService";
import { OneMeal } from "../../interfaces/OneMeal/OneMeal";
import { KalendColors } from "../../constants/KalendColors";
import { OneMealServiceStatus } from "../../enums/OneMealServiceStatus";
import CityService from "../../services/CityService";
import CityResponse from "../../interfaces/City/CityResponse";
import AreaResponse from "../../interfaces/Area/AreaResponse";
import AreaService from "../../services/AreaService";
import { ServiceOfferingEnums } from "../../enums/ServiceOfferingEnums";
import { CookReferenceModelName } from "../../enums/CookReferenceModelName";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import { debounce } from "lodash";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CircularLoader from "../Common/CircularLoader";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);


const cookService = new CookService();
const oneMealService = new OneMealService();
const trialsService = new TrialsService();
const userBundleService = new UserBundleService();
const cityService = new CityService();
const areaService = new AreaService();
const currentDate = localStorage.getItem('omsTimeTableDateFilter') ? moment(localStorage.getItem('omsTimeTableDateFilter')).toDate() : moment().toDate();

const legends = [
  { color: 'darkgrey', label: 'BLOCKED, Leaves/CK_BLOCKED' },
  { color: '#43a047', label: 'SERVICES FULFILLED' },
  { color: '#1d89e4', label: 'COOK_ALLOCATED, COOK_CONFIRMED before SERVICES Start time' },
  { color: '#e53935', label: 'COOK_ALLOCATED, COOK_CONFIRMED after SERVICES Start time' },
  { color: 'black', label: 'PENDING SERVICES' },
];
const CustomToolbar = (props: any) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{props?.label}</span> {/* Display current date */}
    </div>
  );
};


const CookServeCalendar = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [omsLoading, setOmsLoading] = useState<boolean>(true);
  const [trailLoading, setTrialLoading] = useState<boolean>(true);
  const [date, setDate] = useState<Date>(currentDate);
  const [dialog, setDialog] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const [cookOmsMapping, setCookOmsMapping] = useState<any>({});
  const [cityList, setCityList] = useState<CityResponse[]>([]);
  const [selectCity, setSelectCity] = useState<any>(null);
  const [allAreaList, setAllAreaList] = useState<AreaResponse[]>([]);
  const [areaList, setAreaList] = useState<AreaResponse[]>([]);
  const [selectAreaId, setSelectAreaId] = useState<number | null>(null);
  const [selectedArea, setSelectArea] = useState<any>(null)
  const [selectServiceId, setSelectServiceId] = useState<string | string[]>([]);
  const [isMgCook, setIsMgCook] = useState<string>('');
  const [areaInput, setAreaInput] = useState<string>("")

  const eventStyleGetter = (event: any) => {
    let backgroundColor = event.color || "#3174ad";
    let color = ['#e53935', '#1d89e4', 'black'].includes(event.color) ? 'white' : 'black' // Default color
    let border = '1px solid white'

    return {
      style: {
        backgroundColor,
        color,
        border,
      },
    };
  };

  // function mergeContinuousTimeSlots(timeSlots: any[]) {
  //   // Sort the array based on the startAt time
  //   // @ts-ignore
  //   // timeSlots.sort((a: { startAt: string | number | Date; }, b: { startAt: string | number | Date; }) => new Date(a.startAt) - new Date(b.startAt));

  //   timeSlots.sort((a: { start: string | number | Date; }, b: { start: string | number | Date; }) => a.start - b.start);
  //   const mergedSlots = [];
  //   let currentSlot = timeSlots[0];

  //   if(!currentSlot) return [];

  //   for (let i = 1; i < timeSlots.length; i++) {
  //     const currentEnd = currentSlot.end;
  //     const nextStart =timeSlots[i].start;
  //     const currentModelName = currentSlot.referenceModelName;
  //     const nextModelName = timeSlots[i].referenceModelName;
  //     const currentReferenceId = currentSlot.referenceId;
  //     const nextReferenceId = timeSlots[i].referenceId;

  //     if (currentEnd >= nextStart && currentModelName === nextModelName && currentReferenceId === nextReferenceId) {
  //       // Merge the current slot with the next slot
  //       currentSlot.end = timeSlots[i].end;
  //       currentSlot.referenceModelName = timeSlots[i].referenceModelName;
  //       currentSlot.referenceId = timeSlots[i].referenceId;
  //     } else {
  //       // Current slot is not continuous, push it to the merged slots
  //       currentSlot.start = moment(currentSlot.start)
  //           .add(0.001, "hours")
  //       mergedSlots.push(currentSlot);
  //       currentSlot = timeSlots[i];
  //     }
  //   }
  //   if(currentSlot?.isLeave === true) {
  //     currentSlot.end = moment(currentSlot.end)
  //         .subtract(0.001, "hours")
  //   }
  //   // Push the last slot to the merged slots
  //   mergedSlots.push(currentSlot);
  //   return mergedSlots;
  // }

  function mergeContinuousTimeSlots(timeSlots: any[]) {
    if (!timeSlots.length) return [];

    // Sort the array based on the `start` time
    timeSlots.sort(
      (a: { start: Date }, b: { start: Date }) => +new Date(a.start) - +new Date(b.start)
    );

    const mergedSlots = [];
    let currentSlot = timeSlots[0];

    for (let i = 1; i < timeSlots.length; i++) {
      const currentEnd = moment(currentSlot.end);
      const nextStart = moment(timeSlots[i].start);

      // Destructure necessary fields for model and ID checks
      const { referenceModelName: currentModelName, referenceId: currentReferenceId } = currentSlot;
      const { referenceModelName: nextModelName, referenceId: nextReferenceId } = timeSlots[i];

      // Check if slots are continuous and belong to the same reference
      if (
        currentEnd.isSameOrAfter(nextStart) &&
        currentModelName === nextModelName &&
        currentReferenceId === nextReferenceId
      ) {
        // Merge the current slot with the next slot
        currentSlot.end = timeSlots[i].end;
      } else {
        // Modify `currentSlot.start` slightly to adjust for merging
        currentSlot.start = moment(currentSlot.start).add(0.001, "hours").toDate();
        mergedSlots.push(currentSlot); // Push the merged slot
        currentSlot = timeSlots[i]; // Start a new slot
      }
    }

    // Final adjustment for the last slot
    if (currentSlot?.isLeave === true) {
      currentSlot.end = moment(currentSlot.end).subtract(0.001, "hours").toDate();
    }

    mergedSlots.push(currentSlot); // Add the last slot

    return mergedSlots;
  }



  const getColor = (eachSlot: any) => {
    if (eachSlot?.referenceModelName === CookReferenceModelName.ONE_MEAL_SERVICE ||
      eachSlot?.referenceModelName === CookReferenceModelName.COOK_SUBSCRIPTION_V3 ||
      eachSlot?.referenceModelName === CookReferenceModelName.TRIALS_V3) {
      const currentDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
      const slotDateTime = moment(
        moment(eachSlot.date).format('YYYY-MM-DD') + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm"), "YYYY-MM-DD HH:mm:ss"
      ).format();
      if (eachSlot?.serviceStatus === OneMealServiceStatus.FULFILLED) {
        return '#43a047';
      }
      if ([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && !currentDate.isAfter(slotDateTime)) {
        return '#1d89e4'
      }
      if ([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && currentDate.isAfter(slotDateTime) && eachSlot?.checkInTime === null) {
        return '#e53935'
      }
      if ([OneMealServiceStatus.ONGOING].includes(eachSlot?.serviceStatus) && eachSlot?.checkOutTime === null) {
        return '#e53935'
      }
      if (eachSlot?.leaveMarked !== null) {
        return '#a9a9a9';
      }
      return "#fdd734";
    } else if (eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || (eachSlot?.status === 'CK_BLOCKED' && eachSlot?.referenceModelName === null) || ((eachSlot?.status === 'BLOCKED' && eachSlot?.referenceModelName === null))) {
      return '#a9a9a9';
    } else if (OneMealServiceStatus.COOK_ALLOCATED) {
      return 'black';
    }
  };

  const getAllPendingOms = useCallback(
    async (start?: Date) => {
      setOmsLoading(true);
      // setCookOmsMapping({});
      let date = moment(start).format("YYYY-MM-DD");
      let filters: any = { date, paymentStatus: 'PAID', status: TrialsV3Status.PENDING };
      if (selectCity) {
        filters.cityId = selectCity
      }
      if (selectAreaId) {
        filters.areaId = selectAreaId
      }
      let timeSheet: { totalPeople: number; noOfSlots: number }[] = [];
      await oneMealService.getOmsCookingTime().then((res: any) => {
        timeSheet = res;
      })
      function getTime(totalPeople: number) {
        let data = timeSheet.find((eachTime: { totalPeople: number; noOfSlots: number }) => eachTime?.totalPeople === totalPeople);
        if (data) {
          return data?.noOfSlots * 0.5;
        } else {
          return 1.5;
        }
      }
      await oneMealService
        .getAllOneMealService(1000, 0, filters, {})
        .then((res) => {
          const uniqueCook = new Set();
          let tempCookOmsMapping: any = {};
          res.data.forEach((obj: OneMeal) => {
            if (!["CANCELLED", "RESCHEDULED"].includes(obj.status)) {
              let requiredDetails: any = {
                summary: "",
                id: NaN,
                startAt: "",
                endAt: "",
                color: "",
                heading: "",
                cookId: "",
                referenceId: "",
                referenceModelName: "",
                isLeave: false,
                cookDetails: null,
              };
              requiredDetails.omsDetails = obj;
              requiredDetails.summary = `${obj?.id}_ONE_MEAL_SERVICE`;
              uniqueCook.add(obj.cook && obj?.cook.id);
              requiredDetails.id = obj?.id;
              requiredDetails.startAt = moment(
                moment(obj.date).format('YYYY-MM-DD') + "T" + moment(obj.time, "h:mm A").format("HH:mm")
              ).format();
              requiredDetails.referenceModelName = 'ONE_MEAL_SERVICE';
              requiredDetails.referenceId = obj?.id;
              requiredDetails.endAt = moment(requiredDetails.startAt)
                .add(getTime(obj?.totalPeople), "hours")
                .format();

              requiredDetails.start = moment(
                `${obj.date}T${moment(obj.time, "h:mm A").format("HH:mm")}`
              ).toDate();
              // requiredDetails.end=moment(
              //   eachSlot.date + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")
              // ).format();
              requiredDetails.end = moment(requiredDetails.start)
                .add(getTime(obj?.totalPeople), "hours")
                .toDate();
              requiredDetails.title = `${obj?.id}_ONE_MEAL_SERVICE`;
              let index = obj.cook
                ? Array.from(uniqueCook).indexOf(obj?.cook.id) %
                Array.from(uniqueCook).length
                : -1;
              requiredDetails.color =
                index === -1 ? "black" : KalendColors[index];
              if (obj.cook === null) {
                if (tempCookOmsMapping[0]) {
                  tempCookOmsMapping[0].push(requiredDetails);
                } else {
                  tempCookOmsMapping[0] = [requiredDetails];
                }
              }
            }
          });
          if (Object.keys(tempCookOmsMapping)?.length) {
            setCookOmsMapping((preData: any) => {
              return { ...preData, 0: preData?.[0] ? [...preData[0], ...tempCookOmsMapping[0]] : [...tempCookOmsMapping[0]] }
            });
          }
          // setCookOmsMapping((preData: any) => {
          //   return { ...preData, ...tempCookOmsMapping }
          // });
          // setCookOmsMapping((preData: any) => {
          //   return { ...preData, 0: [ ...preData['0'], ...tempCookOmsMapping] }
          // });
          setOmsLoading(false);
        })
        .catch((err) => {
          setOmsLoading(false);
        });
    },
    [selectCity, selectAreaId]
  );

  const getAllPendingTrials = useCallback(
    async (start?: Date) => {
      setTrialLoading(true);
      let date = moment(start).format("YYYY-MM-DD");
      let filters: any = { date, paymentStatus: 'PAID', status: TrialsV3Status.PENDING };
      if (selectCity) {
        filters.cityId = selectCity
      }
      if (selectAreaId) {
        filters.areaId = selectAreaId
      }
      await trialsService
        .getAllTrials(1000, 0, filters, {})
        .then((res) => {
          const uniqueCook = new Set();
          let tempCookOmsMapping: any = {};
          res.data.forEach((obj: any) => {
            if (!["CANCELLED", "RESCHEDULED"].includes(obj.status)) {
              let requiredDetails: any = {
                summary: "",
                id: NaN,
                startAt: "",
                endAt: "",
                color: "",
                heading: "",
                cookId: "",
                referenceId: "",
                referenceModelName: "",
                isLeave: false,
                cookDetails: null,
              };
              requiredDetails.trialDetails = obj;
              requiredDetails.summary = `${obj?.id}_TRIALS_V3`;
              uniqueCook.add(obj.cook && obj?.cook.id);
              requiredDetails.id = obj?.id;
              requiredDetails.startAt = moment(
                moment(obj.date).format('YYYY-MM-DD') + "T" + moment(obj.time, "h:mm A").format("HH:mm")
              ).format();
              requiredDetails.referenceModelName = 'TRIALS_V3';
              requiredDetails.referenceId = obj?.id;
              requiredDetails.endAt = moment(requiredDetails.startAt)
                .add(1.5, "hours")
                .format();
              requiredDetails.start = moment(
                `${obj.date}T${moment(obj.timeSlot, "h:mm A").format("HH:mm")}`
              ).toDate();
              // requiredDetails.end=moment(
              //   eachSlot.date + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")
              // ).format();
              requiredDetails.end = moment(requiredDetails.start)
                .add(1.5, "hours")
                .toDate();
              requiredDetails.title = obj?.referenceModelName ? `${obj?.referenceId}_${obj?.referenceModelName}` : obj?.status === 'CK_BLOCKED' ? 'CK_BLOCKED' : obj?.cookLeaveId ? 'LEAVE' : obj?.status === 'BLOCKED' ? 'BLOCKED' : 'PENDING';
              let index = obj.cook
                ? Array.from(uniqueCook).indexOf(obj?.cook.id) %
                Array.from(uniqueCook).length
                : -1;
              requiredDetails.color =
                index === -1 ? "black" : KalendColors[index];
              if (obj.cook === null) {
                if (tempCookOmsMapping[0]) {
                  tempCookOmsMapping[0].push(requiredDetails);
                } else {
                  tempCookOmsMapping[0] = [requiredDetails];
                }
              }
            }
          });
          if (Object.keys(tempCookOmsMapping)?.length) {
            setCookOmsMapping((preData: any) => {
              return { ...preData, 0: preData?.[0] ? [...preData[0], ...tempCookOmsMapping[0]] : [...tempCookOmsMapping[0]] }
            });
          }
          // setCookOmsMapping((preData: any) => {
          //   return { ...preData, ...tempCookOmsMapping }
          // });
          // setCookOmsMapping((preData: any) => {
          //   return { ...preData, 0: [ ...preData['0'], ...tempCookOmsMapping] }
          // });
          setTrialLoading(false);
        })
        .catch((err) => {
          setTrialLoading(false);
        });
    },
    [selectCity, selectAreaId]
  );

  const getAllCooks = useCallback(() => {
    setLoading(true);
    let selectedDate = moment(date).format("YYYY-MM-DD");
    let filters: any = { date: selectedDate };
    if (selectCity) {
      filters.cityId = selectCity
    }
    if (selectAreaId) {
      filters.areaId = selectAreaId
    }
    if (selectServiceId && Array.isArray(selectServiceId)) {
      filters.serviceId = selectServiceId?.length ? selectServiceId : Object.keys(ServiceOfferingEnums).map((_, i) => i);
    }
    if (isMgCook !== '') {
      filters.isMgCook = isMgCook === 'yes';
    }
    cookService.getAllcookServeCalendar(filters).then((res) => {
      const uniqueCook = new Set();
      let tempCookOmsMapping: any = {};
      let tempDetails: any = {};
      [...res].forEach((obj: any, i: number) => {
        const timeSlots: any[] = [];
        obj?.calender?.forEach((eachSlot: any) => {
          // console.log(eachSlot);
          let requiredDetails: any = {
            summary: "",
            id: NaN,
            startAt: "",
            endAt: "",
            color: "",
            heading: "",
            cookId: "",
            referenceId: "",
            referenceModelName: "",
            isLeave: false,
            cookDetails: {},
          };
          requiredDetails.heading = `${obj?.cook?.firstName} ${obj?.cook?.lastName}`.length < 15 ? `${obj?.cook?.firstName} ${obj?.cook?.lastName}` : `${obj?.cook?.firstName} ${obj?.cook?.lastName}`.substring(0, 15) + "...";
          requiredDetails.cookId = obj?.cook?.cookId;
          requiredDetails.summary = eachSlot?.referenceModelName ? `${eachSlot?.referenceId}_${eachSlot?.referenceModelName}` : eachSlot?.status === 'CK_BLOCKED' ? 'CK_BLOCKED' : eachSlot?.cookLeaveId ? 'LEAVE' : eachSlot?.status === 'BLOCKED' ? 'BLOCKED' : 'PENDING';
          requiredDetails.referenceId = eachSlot?.referenceId;
          requiredDetails.referenceModelName = eachSlot?.referenceModelName;
          requiredDetails.cookDetails = obj?.cook;
          uniqueCook.add(obj?.cook?.cookId);
          requiredDetails.id = eachSlot?.id;
          requiredDetails.startAt = moment(
            eachSlot.date + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")
          ).format();
          requiredDetails.start = moment(
            `${eachSlot.date}T${moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")}`
          ).toDate();
          // requiredDetails.end=moment(
          //   eachSlot.date + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")
          // ).format();
          requiredDetails.end = moment(requiredDetails.start)
            .add(0.5, "hours")
            .toDate();
          requiredDetails.title = eachSlot?.referenceModelName ? `${eachSlot?.referenceId}_${eachSlot?.referenceModelName}` : eachSlot?.status === 'CK_BLOCKED' ? 'CK_BLOCKED' : eachSlot?.cookLeaveId ? 'LEAVE' : eachSlot?.status === 'BLOCKED' ? 'BLOCKED' : 'PENDING';
          requiredDetails.endAt = moment(requiredDetails.startAt)
            .add(0.5, "hours")
            .format();
          requiredDetails.color = getColor(eachSlot);
          tempDetails = { ...requiredDetails };
          if (eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || (eachSlot?.status === 'CK_BLOCKED' && eachSlot?.referenceModelName === null) || (eachSlot?.status === 'BLOCKED' && eachSlot?.referenceModelName === null)) {
            requiredDetails.isLeave = true;
          }
          if (eachSlot?.referenceModelName !== null || eachSlot?.leaveMarked !== null || eachSlot?.cookLeaveId !== null || eachSlot?.status === 'CK_BLOCKED' || eachSlot?.status === 'BLOCKED') {
            if (eachSlot?.referenceModelName !== '') {
              timeSlots.push(requiredDetails);
            }
          }
        })
        let mergedSlots = mergeContinuousTimeSlots(timeSlots);
        if (mergedSlots.length === 0) {
          tempDetails.start = '';
          tempDetails.end = '';
          mergedSlots = [tempDetails];
        }
        if (obj.cookId !== null) {
          if (tempCookOmsMapping[obj?.cook?.cookId]) {
            tempCookOmsMapping[obj?.cook?.cookId].push(...mergedSlots);
          } else {
            tempCookOmsMapping[obj?.cook?.cookId] = [...mergedSlots];
          }
        }
      });
      setCookOmsMapping((tempData: any) => {
        return { ...tempData, ...tempCookOmsMapping }
      });
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  }, [date, selectCity, selectAreaId, selectServiceId, isMgCook]);

  const DataContainer = ({ title = '', value = '---' }: { title: string, value: string }) => {
    return (
      <div>
        <b style={{ minWidth: "170px", display: "inline-block" }}>
          {title}
        </b>
        <span>{value}</span>
      </div>
    )
  };
  const DialogDetails = (props: any) => {
    let cook = props?.data?.cookDetails;
    const [data, setData] = useState<any>(null);
    const [redirectLink, setRedirectLink] = useState('')
    useEffect(() => {
      if (props?.data?.referenceModelName === 'ONE_MEAL_SERVICE') {
        setRedirectLink(`/#/OneMealService/${props?.data?.referenceId}/show`);
        oneMealService.getOneMealService(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
        })
      } else if (props?.data?.referenceModelName === 'TRIALS_V3') {
        setRedirectLink(`/#/Trials/${props?.data?.referenceId}/show`);
        trialsService.getTrialsById(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
        })
      } else if (props?.data?.referenceModelName === 'COOK_SUBSCRIPTION_V3') {
        cookService.getSubscriptionForACook(props?.data?.referenceId).then((data: any) => {
          setRedirectLink(`/#/UserBundles/${data?.userBundleMappingId}/show/7`);
          userBundleService.fetchOneUserBundleMapping(data?.userBundleMappingId).then((res: any) => {
            setData(res?.data);
          })
        })
      }
    }, [props?.data?.referenceId, props?.data?.referenceModelName]);
    return (
      <div>
        <Dialog
          onClose={props.handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
        >
          <DialogTitle>
            <b>{props?.data?.referenceModelName}</b>
            <Button
              variant={'contained'}
              sx={{ borderRadius: 30, fontSize: '10px', ml: 2 }}
              size={'small'}
              component={Link}
              href={redirectLink}
            >
              {'View Full Details'}
            </Button>
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            {/*{!data?.razorpayOrder?.amount && }*/}
            {(props?.data?.referenceModelName === 'ONE_MEAL_SERVICE' &&
                data?.oneMealServiceAmount !== 0 &&
                data?.oneMealServiceAmount === data?.discount) && (
                <Box
                    display={'flex'} justifyContent={'center'} alignItems={'center'}
                    sx={{
                      p: '4px',
                      backgroundColor: '#ffca69',
                      borderRadius: '12px',
                      mb: 1,
                    }}
                >
                  <Typography fontWeight={'600'}>
                    {'Combo Visit: 100% Coupon Applied'}
                  </Typography>
                </Box>
            )}
            <DataContainer title={'Status:'} value={data?.status} />
            {cook && (
              <div>
                <DataContainer title={'Cook Id:'} value={cook?.cookId} />
                <DataContainer title={'Cook Name:'} value={cook?.firstName + " " + cook?.lastName} />
                <DataContainer title={'Cook PhoneNo:'} value={data?.cookPhoneNumber} />
                <DataContainer title={'Onboarding Status:'} value={cook?.onboardingStatus} />
              </div>
            )}
            <DataContainer title={'Customer Name:'} value={data?.customerName} />
            <DataContainer title={'Customer PhoneNo:'} value={data?.customerPhoneNumber} />
            <DataContainer title={'City:'} value={data?.cityName} />
            <DataContainer title={'Area:'} value={data?.areaName} />
            <DataContainer title={'Locality:'} value={data?.localityName} />
            <DataContainer title={'Customer Address:'} value={data?.house?.address + " " + data?.house?.locality + " " + data?.house?.city} />
            <DataContainer title={'Total People:'} value={data?.totalPeople} />
            <DataContainer title={'Allocator Name:'} value={data?.allocatorName} />
            <DataContainer title={'Date:'} value={moment(data?.date).format('DD-MM-YYYY')} />
            <DataContainer title={'Time:'} value={data?.time} />
            <DataContainer title={'Check In Time:'} value={data?.checkInTime} />
            <DataContainer title={'Customer Feedback:'} value={data?.customerFeedback} />
            <DataContainer title={'Check Out Time:'} value={data?.checkOutTime} />
            <DataContainer title={'Customer Comments:'} value={data?.customerComments?.[0]?.comment} />
            <DataContainer title={'Customer Rating:'} value={data?.customerRating} />
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              color={"error"}
              onClick={() => {
                props.handleClose(false);
              }}
              size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const onEventClick = (data: any) => {
    if (data?.referenceId) {
      setDialog(true);
    }
    setDetails(data);
  };


  // const getMenuItems = (enumData: string[]) => {
  //   return Object.values(enumData).map((e) => (
  //     <MenuItem key={e} value={e}>
  //       {e}
  //     </MenuItem>
  //   ))
  // }
  const calendarRefs = useRef<any>({});


  // Handler to sync scrolling across calendars
  const syncScroll = (scrollTop: any, sourceKey: any) => {
    Object.entries(calendarRefs.current).forEach(([key, ref]: any) => {
      // console.log(scrollTop, sourceKey);
      // console.log(key);
      // console.log(ref);
      if (key !== Number(sourceKey) && ref) {
        const scrollableDiv = ref.querySelector('.rbc-time-content');
        // console.log(scrollableDiv);
        if (scrollableDiv && scrollableDiv.scrollTop !== scrollTop) {
          scrollableDiv.scrollTop = scrollTop;
        }
      }
    });
  };

  useEffect(() => {
    const listeners: any = []; // Track listeners for cleanup

    Object.entries(calendarRefs.current).forEach(([key, ref]: any) => {
      if (ref) {
        const scrollableDiv = ref.querySelector('.rbc-time-content');
        if (scrollableDiv) {
          const handleScroll = () => syncScroll(scrollableDiv.scrollTop, key);
          scrollableDiv.addEventListener('scroll', handleScroll);
          listeners.push({ scrollableDiv, handleScroll });
        }
      }
    });

    // Cleanup all listeners on unmount
    return () => {
      listeners.forEach(({ scrollableDiv, handleScroll }: any) => {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      });
    };
  }, [cookOmsMapping]);
  useEffect(() => {
    cityService.fetchAllCities(1000, 0, { isCurrentlyServing: "YES" }, {}).then((res) => {
      setCityList(res?.data);
    });
    areaService.fetchAllAreas(1000, 0, {}, { "field": "name", "order": "ASC" }).then((res) => {
      setAllAreaList(res?.data);
      setAreaList(res?.data);
    })
  }, [])

  useEffect(() => {
    const debouncedFetchAreas = debounce(() => {
      if ((!selectCity || selectCity === 0) && !areaInput) {
        setAreaList(allAreaList);
      } else {
        setAreaList([]);
        const filters: Record<string, number | string> = {};
        if (selectCity) {
          filters.cityId = selectCity;
        }
        if (areaInput) {
          filters.name = areaInput;
        }
        areaService.fetchAllAreas(1000, 0, filters, { "field": "name", "order": "ASC" }).then((res) => {
          setAreaList(res?.data);
        });
      }
    }, 500);

    debouncedFetchAreas();

    return () => {
      debouncedFetchAreas.cancel(); // Cancel any pending debounced calls
    };
  }, [selectCity, areaInput, allAreaList]);


  useEffect(() => {
    setLoading(true);
    setCookOmsMapping({});
    getAllCooks();
    getAllPendingTrials(date);
    getAllPendingOms(date);
    const interval = setInterval(() => {
      getAllCooks();
      getAllPendingTrials(date);
      getAllPendingOms(date);
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(interval);
    }
  }, [date, getAllCooks, getAllPendingOms, getAllPendingTrials]);
  return (
    <Authenticated>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {dialog && (
          <DialogDetails
            open={dialog}
            handleClose={() => setDialog(false)}
            data={details}
          />
        )}
        <div style={{ marginTop: "15px", margin: "15px" }}>
          <form style={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ minWidth: 140, marginRight: 2 }}>
              <InputLabel id="for_Type">City</InputLabel>
              <Select
                labelId="for_Type"
                id="Type"
                label="City"
                value={selectCity}
                disabled={loading || trailLoading || omsLoading}
                onChange={(event) => {
                  let value: any = event.target.value
                  setSelectCity(value);
                  setAreaList([])
                  setAreaInput("");
                  setSelectAreaId(null)
                  setSelectArea(null)
                }}
              >
                <MenuItem key={'ALL_CITY'} value={0}>
                  {'ALL_CITIES'}
                </MenuItem>
                {cityList.map((eachCity) => (
                  <MenuItem key={eachCity?.name} value={eachCity?.id}>
                    {eachCity?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 140, marginRight: 2, width: "200px", marginBottom: 1 }}>
              <Autocomplete
                id="select-Area-select"
                options={areaList?.map((each: { id: number; name: string }) => {
                  return { label: each?.name, value: each?.id }
                })}
                onChange={(_, data: any) => {
                  setSelectAreaId(data?.value)
                  setSelectArea(data)
                }}
                value={selectedArea}
                renderInput={(params) => <TextField {...params} label="Area" />}
                inputValue={areaInput}
                onInputChange={(_, newValue) => {
                  setAreaInput(newValue);
                }}
                disabled={loading || trailLoading || omsLoading}
              />
            </FormControl>
            {/*<ArrowBackIosIcon*/}
            {/*  style={{ padding: "15px" }}*/}
            {/*  onClick={() =>*/}
            {/*    setDate((e) => moment(e).subtract(1, "d").toDate())*/}
            {/*  }*/}
            {/*/>*/}
            <DatePicker
              mask=""
              label="Select Date"
              inputFormat="dd MMM yyyy"
              value={date}
              disabled={loading || trailLoading || omsLoading}
              onChange={(newValue: any) => {
                setDate(newValue);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
              onAccept={
                (newValue: any) => {
                  localStorage.setItem("omsTimeTableDateFilter", moment(newValue).toString());
                }
              }
            />
            <ArrowForwardIosIcon
              style={{ padding: "15px" }}
              onClick={() => setDate((e) => moment(e).add(1, "d").toDate())}
            />
            {/* <FormControl sx={{ minWidth: 140 }}>*/}
            {/*  <InputLabel id="for_Type">Status</InputLabel>*/}
            {/*  <Select*/}
            {/*    labelId="for_Type"*/}
            {/*    id="Type"*/}
            {/*    label="Status"*/}
            {/*    value={paymentStatus || OmsTimeTableStatusEnum.ALL}*/}
            {/*    onChange={(event) => {*/}
            {/*      if (event.target.value === OmsTimeTableStatusEnum.ALL) {*/}
            {/*        setPaymentStatus(null);*/}
            {/*        return;*/}
            {/*      }*/}
            {/*      setPaymentStatus(event.target.value);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*  {*/}
            {/*    getMenuItems(Object.values(OmsTimeTableStatusEnum))*/}
            {/*  }*/}
            {/*  </Select>*/}
            {/*</FormControl> */}

            <FormControl sx={{ minWidth: 140, maxWidth: '400', marginRight: 2 }}>
              <InputLabel id="for_Type">Service</InputLabel>
              <Select
                multiple
                labelId="for_Type"
                id="Type"
                label="Service"
                disabled={loading || trailLoading || omsLoading}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (selectedValue) {
                    setSelectServiceId(selectedValue);
                  }
                }}
                value={selectServiceId}
              >
                {Object.keys(ServiceOfferingEnums).map((key, index) => {
                  return (
                    <MenuItem key={key} value={index + 1}>
                      {key}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 140, maxWidth: '400', marginRight: 2 }}>
              <InputLabel id="for_Type">MG Cook</InputLabel>
              <Select
                labelId="mg_cook"
                label="MG Cook"
                disabled={loading || trailLoading || omsLoading}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setIsMgCook(selectedValue);
                }}
                value={isMgCook}
              >
                <MenuItem value={''}>{'Show All'}</MenuItem>
                <MenuItem value={'yes'}>{'Yes'}</MenuItem>
                <MenuItem value={'no'}>{'No'}</MenuItem>
              </Select>
            </FormControl>
            <Button sx={{ marginLeft: "1rem" }} size="small" disabled={(selectCity === null && selectAreaId === null && selectServiceId.length === 0 && date === new Date()) || loading || trailLoading || omsLoading} variant="outlined" onClick={() => {
              // setPaymentStatus(null);
              if (selectCity !== null) setSelectCity(0);
              setSelectArea(null)
              setAreaInput("");
              if (selectAreaId !== null) setSelectAreaId(null);
              setDate(new Date());
              if (selectServiceId.length !== 0) setSelectServiceId([]);
              if (isMgCook !== '') setIsMgCook('');
              if (areaList.length > 0) setAreaList([])
            }}>Reset</Button>
          </form>
        </div>
        {/*<Box m={2} display={'flex'} flexDirection={'row-reverse'}>*/}
        {/*  {legends?.map((eachLegend) => (*/}
        {/*      <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>*/}
        {/*        <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} mr={1} />*/}
        {/*        <Typography>*/}
        {/*          {eachLegend?.label}*/}
        {/*        </Typography>*/}
        {/*      </Box>*/}
        {/*  ))}*/}
        {/*</Box>*/}
        <div style={{ display: "flex" }}>
          <div style={{ textAlign: "center", width: "200px" }} key={'0'} ref={(el) => (calendarRefs.current['0'] = el)} className={"first-calendar"}>
            <Box fontWeight={700} fontSize={13} padding={'6px'} marginLeft={'30px'}>Pending OMS/ Trials</Box>
            {/* <Kalend
                      kalendRef={props.kalendRef}
                      initialView={CalendarView.DAY}
                      disabledViews={[
                        CalendarView.AGENDA,
                        CalendarView.THREE_DAYS,
                        CalendarView.MONTH,
                        CalendarView.WEEK,
                      ]}
                      timeFormat={'12'}
                      onEventClick={onEventClick}
                      events={value}
                      initialDate={moment(date).format("YYYY-MM-DD")}
                      hourHeight={60}
                      disabledDragging={true}
                      onStateChange={props.onStateChange}
                      selectedView={props.selectedView}
                      showTimeLine={true}
                      isDark={false}
                      autoScroll={false}
                    /> */}
            <Calendar
              localizer={localizer}
              events={cookOmsMapping[0]}
              defaultView="day"
              views={["day"]} // Restrict to single day view
              date={date} // Set the calendar date
              style={{ height: "100%", backgroundColor: "white" }} // Full height for better visibility
              components={{
                toolbar: CustomToolbar, // Use custom toolbar to hide navigation buttons
              }}
              step={60}         // Set each slot to 30 minutes
              timeslots={1}
              onSelectEvent={onEventClick}
              eventPropGetter={eventStyleGetter}
              min={new Date(2024, 0, 1, 5, 0)} // Start view from 5 PM
              max={new Date(2024, 0, 1, 23, 59)}
            />
          </div>
          {
            (loading || omsLoading || trailLoading) ? <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100vw'}>
              <CircularLoader/>
            </Box> :
              <div style={{ display: "flex", height: "100%", overflow: "scroll" }}>
                {Object.keys(cookOmsMapping).length !== 0 &&
                  Object.entries(cookOmsMapping).map(([key, value]: any, index) => (
                    key !== '0' ? <div style={{ textAlign: "center", width: "200px" }} key={key} ref={(el) => (calendarRefs.current[key] = el)}>
                      {
                        key !== "0"
                        && <Button
                          // variant={'contained'}
                          sx={{ borderRadius: 30, fontSize: '13px', ml: 2, position: 'sticky' }}
                          size={'small'}
                          component={Link}
                          href={`/#/Cooks/${value[0]?.cookId}/show`}
                        >
                          {`${value[0]?.heading}`}
                        </Button>
                      }
                      {/* <Kalend
                      kalendRef={props.kalendRef}
                      initialView={CalendarView.DAY}
                      disabledViews={[
                        CalendarView.AGENDA,
                        CalendarView.THREE_DAYS,
                        CalendarView.MONTH,
                        CalendarView.WEEK,
                      ]}
                      timeFormat={'12'}
                      onEventClick={onEventClick}
                      events={value}
                      initialDate={moment(date).format("YYYY-MM-DD")}
                      hourHeight={60}
                      disabledDragging={true}
                      onStateChange={props.onStateChange}
                      selectedView={props.selectedView}
                      showTimeLine={true}
                      isDark={false}
                      autoScroll={false}
                    /> */}
                      <Calendar
                        localizer={localizer}
                        events={value}
                        defaultView="day"
                        views={["day"]} // Restrict to single day view
                        date={date} // Set the calendar date
                        style={{ height: "100%", backgroundColor: "white" }} // Full height for better visibility
                        components={{
                          toolbar: CustomToolbar, // Use custom toolbar to hide navigation buttons
                        }}
                        step={60}         // Set each slot to 30 minutes
                        timeslots={1}
                        onSelectEvent={onEventClick}
                        eventPropGetter={eventStyleGetter}
                        min={new Date(2024, 0, 1, 5, 0)}// Start view from 5 PM
                        max={new Date(2024, 0, 1, 23, 59)} // End view at 11:59 PM
                      />
                    </div> : <></>
                  ))}
              </div>
          }
        </div>
      </div>
      <Box display={'flex'} flexDirection={'row'} position={"sticky"} bottom={0} zIndex={1000}>
        <Box m={2} display={'flex'} flexDirection={'column'}>
          {[legends[0], legends[1]]?.map((eachLegend) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} id={eachLegend?.label}>
              <Box sx={{ width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1 }} m={0.5} mr={1} />
              <Typography fontSize={12}>
                {eachLegend?.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box m={2} display={'flex'} flexDirection={'column'}>
          {[legends[2], legends[3]]?.map((eachLegend) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} id={eachLegend?.label}>
              <Box sx={{ width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1 }} m={0.5} mr={1} />
              <Typography fontSize={12}>
                {eachLegend?.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box m={2} display={'flex'} flexDirection={'column'}>
          {[legends[4]]?.map((eachLegend) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }} id={eachLegend?.label}>
              <Box sx={{ width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1 }} m={0.5} mr={1} />
              <Typography fontSize={12}>
                {eachLegend?.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Authenticated>
  );
};
export default CookServeCalendar;

import {Button as MUIButton, Stack} from '@mui/material';
import {
    Show,
    Tab,
    TabbedShowLayout, useRecordContext, useRefresh, useShowContext,
} from 'react-admin';
import ChefForPartyMetaShow from "./ChefForPartyMetaShow";
import React, {useState} from "react";
import AssignCookOnChefForParty from "../Common/AssignCookOnChefForParty";
import {EditDishesChefForParty} from "../Common/EditDishesChefForParty";
import ChefForPartyCancelDialog from "../Common/ChefForPartyCancelDialog";
import UpdateCommentInChefForParty from "../Common/UpdateCommentInChefForParty";
import ChefForPartyReScheduleDialog from "../Common/ChefForPartyReScheduleDialog";
import ChefForPartOrderTab from './ChefForPartOrderTab';
import {useLocation, useNavigate} from 'react-router-dom';
import ChefForPartCooksTab from './ChefForPartyCooksTab';
import ChangeCouponChefForPartyDialog from '../Common/ChangeCouponChefForPartyDailog';
import { PaymentStatusEnum } from '../../enums/PaymentStatusEnum';
import ChefForPartStatusEnum from '../../enums/ChefForPartStatusEnum';
import ChefForPartyService from '../../services/ChefForPartyService';
import { useAppSelector } from '../../store/hooks';
import GroupPermissionFunction from '../../utils/groupPermissionFunction';
import { UserGroupsEnum } from '../../enums/UserGroupsEnum';
import UpdatePartyRemainingPaymentStatusDialog from '../Common/UpdatePartyRemainingPaymentStatusDialog';
import ChefOrderSummaryDialog from "../Common/ChefOrderSummaryDialog";

const chefForPartyService = new ChefForPartyService();
interface InputProps {
    name: any;
    callback: (data: any) => void;
    color?:
        | "inherit"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning"
        | undefined;
}

const CustomButton = (props: InputProps) => {
    let record = useRecordContext();
    return (
        <MUIButton
            onClick={() => {
                props.callback(record);
            }}
            size={"small"}
            color={props.color || "secondary"}
            variant={"contained"}
            style={{ width: "max-content", fontSize: "12px" }}
        >
            {props.name}
        </MUIButton>
    );
};

const ChefForPartyTab = () => {
    let location = useLocation();
    let partyId = location.pathname.split('/')[2]
    return(
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label={"Details"} key={"Details"}>
                <ChefForPartyMetaShow />
            </Tab>
            <Tab label={"Orders"} key={"Orders"}>
                <ChefForPartOrderTab partyId={parseInt(partyId)}/>
            </Tab>
            <Tab label={"Cooks"} key={"Cooks"}>
                <ChefForPartCooksTab partyId={parseInt(partyId)}/>
            </Tab>
            {/*<Tab label={"Services"} key={"Services"}>*/}
            {/*<AreaServicesTab />*/}
            {/*</Tab>*/}
        </TabbedShowLayout>
    )
};

const CTAButtons = (props: any) => {
    const { record } = useShowContext();
    let navigate = useNavigate();
    const refresh = useRefresh();
    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
    return (
      <div>
        <Stack direction={"row"} style={{ marginBottom: "10px" }} gap={2}>
          {![
            ChefForPartStatusEnum.CANCELLED,
            ChefForPartStatusEnum.RESCHEDULED,
            ChefForPartStatusEnum.FULFILLED,
          ].includes(record?.status) && [
            <CustomButton
              name={"Edit Chef For party"}
              color={"warning"}
              callback={(data) => {
                  navigate(
                      `/ChefForParty/${data?.id}`
                  );
              }}
            />,
            <CustomButton
              name={"Assign Cook"}
              color={"primary"}
              callback={(data) => {
                props.setSelectedData(data);
                props.setOpenAssignCook(true);
              }}
            />,
            <CustomButton
              name={"Add Dishes"}
              color="primary"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setDishModel(true);
              }}
            />,
            <>
              {record?.status === ChefForPartStatusEnum.ONGOING && (
                <CustomButton
                  name={"Mark As FulFilled"}
                  color="primary"
                  callback={(data: any) => {
                      chefForPartyService.markAsFulFilled(data?.id).then(() =>{
                        refresh()
                      })
                  }}
                />
              )}
            </>,
            <>
              {record?.status === ChefForPartStatusEnum.CHEF_CONFIRMED && (
                <CustomButton
                  name={"Mark As OnGoing"}
                  color="primary"
                  callback={(data: any) => {
                    chefForPartyService.markAsOnGoing(data?.id).then(() =>{
                      refresh()
                    })
                }}
                />
              )}
            </>,
            <>
              {record?.remainingAmountPaymentStatus ===
                PaymentStatusEnum.NOT_PAID && (
                <CustomButton
                  name={"Change Coupon"}
                  color="primary"
                  callback={(data: any) => {
                    props.setSelectedData(data);
                    props.setChangeCouponModel(true);
                  }}
                />
              )}
            </>,
            <CustomButton
              name={"Cancel Chef For Party"}
              color="error"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setCancelModel(true);
              }}
            />,
            <CustomButton
              name={"Reschedule Chef For Party"}
              color="error"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setReScheduleModel(true);
              }}
            />,

          ]}
          {![
            ChefForPartStatusEnum.CANCELLED,
            ChefForPartStatusEnum.RESCHEDULED,
            ChefForPartStatusEnum.FULFILLED,
            ].includes(record?.status) &&
            ![PaymentStatusEnum.PAID]
            .includes(record?.remainingAmountPaymentStatus) &&
            GroupPermissionFunction(groupsArray, [
                UserGroupsEnum.ADMIN,
            ]) && (
                <CustomButton
                  name={"Update Remaining Payment Status"}
                  color="primary"
                  callback={() => {
                    props.setUpdateRemainingPaymentStatusDialog(true);
                  }}
                />
          )}
        </Stack>
        <Stack direction={"row"} style={{ marginBottom: "10px" }} gap={2}>
          <CustomButton
            name={"Allocation Comment"}
            color="primary"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setUpdateCommentDialog(true);
              props.setInitiatedBy(1);
            }}
          />
          <CustomButton
            name={"Supply Comments"}
            color="primary"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setUpdateCommentDialog(true);
              props.setInitiatedBy(2);
            }}
          />
          <CustomButton
            name={"Customer Comments"}
            color="primary"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setUpdateCommentDialog(true);
              props.setInitiatedBy(3);
            }}
          />
          <CustomButton
            name={"View Order Summary"}
            color="success"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setOrderSummary(true);
            }}
          />
        </Stack>
      </div>
    );
}

function ChefForPartyShow() {

    const [selectedData, setSelectedData] = useState<any>(null);
    const [initiatedBy, setInitiatedBy] = useState<any>();
    const [openAssignCook, setOpenAssignCook] = useState(false);
    const [dishModel, setDishModel] = useState<boolean>(false);
    const [changeCouponModel, setChangeCouponModel] = useState<boolean>(false);
    const [cancelModel, setCancelModel] = useState<boolean>(false);
    const [reScheduleModel, setReScheduleModel] = useState<boolean>(false);
    const [updateCommentDialog, setUpdateCommentDialog] = useState(false);
    const [orderSummary, setOrderSummary] = useState(false);
    const [updateRemainingPaymentStatusDialog,setUpdateRemainingPaymentStatusDialog]=useState(false);

    return (
        <Show actions={false}>
            <AssignCookOnChefForParty
                data={selectedData}
                open={openAssignCook}
                setOpen={setOpenAssignCook}
                trialId={'1'}
            />
            {changeCouponModel && (
                <ChangeCouponChefForPartyDialog
                    data={selectedData}
                    open={changeCouponModel}
                    callback={() => {
                        setChangeCouponModel(false);
                    }}
                />
            )}
            {dishModel && (
                <EditDishesChefForParty
                    data={selectedData}
                    open={dishModel}
                    callback={() => setDishModel(false)}
                />
            )}
            {cancelModel && <ChefForPartyCancelDialog
                data={selectedData}
                open={cancelModel}
                callback={() => {
                    setCancelModel(false);
                }}
            />}
            {reScheduleModel && <ChefForPartyReScheduleDialog
                data={selectedData}
                open={reScheduleModel}
                callback={() => {
                    setReScheduleModel(false);
                }}
            />}
            {orderSummary && <ChefOrderSummaryDialog
                data={selectedData}
                open={orderSummary}
                callback={() => {
                    setOrderSummary(false);
                }}
            />}
            <UpdateCommentInChefForParty
                initiatedBy={initiatedBy}
                data={selectedData}
                open={updateCommentDialog}
                setOpen={setUpdateCommentDialog}
            />
            <UpdatePartyRemainingPaymentStatusDialog
              open={updateRemainingPaymentStatusDialog}
              setOpen={setUpdateRemainingPaymentStatusDialog}
            />
            <CTAButtons
                setSelectedData={setSelectedData}
                setOpenAssignCook={setOpenAssignCook}
                setDishModel={setDishModel}
                setCancelModel={setCancelModel}
                setReScheduleModel={setReScheduleModel}
                setInitiatedBy={setInitiatedBy}
                setUpdateCommentDialog={setUpdateCommentDialog}
                setChangeCouponModel={setChangeCouponModel}
                setOrderSummary={setOrderSummary}
                setUpdateRemainingPaymentStatusDialog={setUpdateRemainingPaymentStatusDialog}
            />
            <ChefForPartyTab />
        </Show>
    )
}

export default ChefForPartyShow;
